<template>
  <div>
    <a-input-search
      placeholder="请输入部门/姓名/拼音/联系方式搜索"
      enter-button
      @search="onSearch"
    ></a-input-search>

        <template v-if="selectedList.length > 0">
      <div class="title">
        <span>已选择</span>
        <a-icon type="close" @click="clearSelected" title="点我清空已选择" />
      </div>
      <a-space class="recent-selected-list">
        <a-tag
          color="blue"
          style="margin-bottom: 8px"
          v-for="item in selectedList"
          :key="item.userId"
          :closable="true"
          @close="removeSelected(item)"
        >
          {{ item.name }}
        </a-tag>
      </a-space>
    </template>

    <Padding />

    <div class="list">
      <div class="header">人员</div>
      <div
        class="item"
        v-for="item in searchList"
        :key="item.userId"
        @click="onClick(item)"
        :class="
          selectedList.findIndex((element) => item.userId === element.userId) >
          -1
            ? 'active'
            : ''
        "
      >
        <div>
          <span>
            {{ item.name }}
          </span>
          <span v-if="item.deptUniqueName"> ({{ item.deptUniqueName }}) </span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    index: {
      type: Number,
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      searchValue: "",
    };
  },

  computed: {
    ...mapState("employeeSelector", ["list"]),

    searchList() {
      let arr = [];
      if (this.searchValue && this.searchValue.trim()) {
        const searchValue = this.searchValue.trim();
        for (let i = 0; i < this.list.length; i++) {
          const item = this.list[i];
          // 部门
          if (
            item.deptUniqueName &&
            item.deptUniqueName.indexOf(searchValue) > -1
          ) {
            arr.push(item);
          }
          // 拼音
          if (item.code && item.code.indexOf(searchValue) > -1) {
            arr.push(item);
          }
          // 拼音简拼
          if (item.firstLetter && item.firstLetter.indexOf(searchValue) > -1) {
            arr.push(item);
          }
          if (item.name && item.name.indexOf(searchValue) > -1) {
            arr.push(item);
          }
          if (item.mobile && item.mobile.indexOf(searchValue) > -1) {
            arr.push(item);
          }
        }
      }

      return arr.length > 0 ? arr : this.list;
    },
  },

  methods: {
    onSearch(value) {
      this.searchValue = value;
    },

    onClick(item) {
      const list = this.selectedList;
      const index = list.findIndex((element) => element.userId === item.userId);
      if (index === -1) {
        this.$emit("setSelectedList", {
          index: this.index,
          selectedList: [...this.selectedList, item],
        });
      } else {
        const newList = [...this.selectedList];
        newList.splice(index, 1);

        this.$emit("setSelectedList", {
          index: this.index,
          selectedList: newList,
        });
      }
    },

    // 清空已选择
    clearSelected() {
      this.$emit("setSelectedList", {
        index: this.index,
        selectedList: [],
      });
    },

    // 移除已选择的员工
    removeSelected(item) {
      const index = this.selectedList.findIndex(
        (element) => element.userId === item.userId
      );

      const newList = [...this.selectedList];
      newList.splice(index, 1);

      this.$emit("setSelectedList", {
        index: this.index,
        selectedList: newList,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  height: 400px;
  overflow-y: auto;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

  .header {
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: bold;
    padding: 12px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px;
  }
  .item:hover,
  .active {
    background-color: #1890ff;
    color: #fff;
  }
}

// 最近选择
.title {
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recent-selected-list {
  display: flex;
  flex-wrap: wrap;
}
</style>