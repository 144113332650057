<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="item.value">
          <div class="title">
            <img :src="imageTitle" alt="" />
            {{ item.name }}
          </div>
          <HRSelector
            :index="index"
            :selectedList="item.selectedList"
            @setSelectedList="setSelectedList"
          />
        </div>
      </div>
      <div class="center">
        <a-space>
          <a-button type="primary" @click="submit" :loading="saveLoading"
            >保存</a-button
          >
          <a-button @click="cancel">关闭</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import HRSelector from "./components/hr-selector.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import { fetchHRList, inviteHR } from "@/api/recruit";

export default {
  components: {
    HRSelector,
  },

  data() {
    return {
      pidList: [], // 面试者 id 数组
      imageTitle: require("@/assets/recruit/icon-hr.png"),
      list: [],

      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    // 面试阶段
    phaseList() {
      return this.findDataDict("recruit_phase").filter(
        (item) => item.name !== "终试2"
      );
    },

    ...mapState("employeeSelector", {
      userList: "list",
    }),
  },

  mounted() {
    const { query } = this.$route;
    const { ids } = query || {};
    if (ids) {
      this.pidList = ids.split(",");

      fetchHRList({
        pidList: this.pidList,
      }).then((res) => {
        if (Array.isArray(res)) {
          this.list = this.phaseList.map((item) => {
            const userList = res.filter(
              (element) => element.phase === item.value
            );

            return {
              ...item,
              selectedList: userList.map((user) => {
                return {
                  userId: user.userId,
                  name: user.userName,
                };
              }),
            };
          });
        }
      });
    }

    if (this.userList.length === 0) {
      this.getList(); // 获取人员选择器数据
    }
  },

  methods: {
    ...mapActions("employeeSelector", ["getList"]),

    setSelectedList({ index, selectedList }) {
      this.list.splice(index, 1, {
        ...this.list[index],
        selectedList,
      });
    },
    submit() {
      let emptyNum = this.list.length;
      this.list.forEach((item) => {
        if (item.selectedList.length === 0) {
          emptyNum--;
        }
      });
      if (emptyNum === 0) {
        this.$message.error("请选择面试官！");
        return;
      }

      if (!this.pidList.length) {
        this.$message.error("请选择面试者！");
        return;
      }

      console.log(this.pidList, this.list);
      const interviewerList = [];
      this.list.forEach((item) => {
        item.selectedList.forEach((element) => {
          interviewerList.push({
            phase: item.value,
            userId: element.userId,
            userName: element.name,
          });
        });
      });
      console.log(interviewerList);
      this.saveLoading = true;
      inviteHR({
        pidList: this.pidList,
        interviewerList,
      })
        .then(() => {})
        .finally(() => {
          this.saveLoading = false;
        });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #f0f0f0;
  gap: 1px;

  .item {
    padding: 16px;
    background-color: #fff;

    .title {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 12px;
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: bold;

      img {
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}
</style>